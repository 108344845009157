import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import ArticlesView from '../views/Articles.vue'
import EpisodeView from '../views/Episode.vue'
import PodsView from '../views/Pods.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/pods',
    name: 'Pods',
    component: PodsView
  },
  {
    path: '/articles/:year/:month/:day',
    name: 'Articles',
    component: ArticlesView
  },
  {
    path: '/episode/:programId/:episodeId/:audioId',
    name: 'Episode',
    component: EpisodeView
  }
  // ,
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
