<template>
  <div class="articles">
    <!-- <section class="hero is-small is-primary has-text-centered">
      <div class="hero-body">
        <h1 class="title">Textradio.se</h1>
        <h2 class="subtitle">&ndash; transkriberar nyheter från Sveriges Radio.</h2>
      </div>
    </section> -->

    <div class="content">
      <div class="block has-text-centered">
        <flat-pickr v-model="date" :config="dateConfig"  />
      </div>
       <div class="block has-text-centered">
        <span>Välj text-format: </span>
        <div class="block">
          <b-radio v-model="textFormat"
          name="textformat"
          native-value="flowing">
          Flödande
          </b-radio>
          <b-radio v-model="textFormat"
          name="textformat"
          native-value="segments">
          Segmenterat
          </b-radio>
        </div>
      </div>
      <section class="articles container">
        <p v-if="articlesLoading" class="has-text-centered">Laddar dagens artiklar...</p>
        <p v-if="articles.length < 1 && !articlesLoading" class="has-text-centered">Hittade tyvärr inga transkriberade artiklar.</p>
        <article class="box" v-for="article in articles" :key="article.id">
          <h2>{{ article.title }}</h2>
          <a :href="article.articleUrl">{{ article.articleUrl }}</a>

          <h3>Beskrivning</h3>
          <p>{{ article.description }}</p>

          <h3>Transkript</h3>
          <p v-if="!article.segments">{{ article.transcript }}</p>
          <div v-if="article.segments" class="transcript block">
            <p v-if="textFormat == 'flowing'">
              <span class="segment block" v-for="segment in article.segments" :key="segment.transcript">
                <p v-if="segment.lang != 'sv' && segment.lang != 'nocaptions'">
                  {{ segment.transcript }}
                </p>
                <span v-if="segment.lang == 'sv' || segment.lang == 'nocaptions'">
                  {{ segment.transcript }}
                </span>
              </span>
            </p>

            <div v-if="textFormat == 'segments'">
              <div class="segment block" v-for="segment in article.segments" :key="segment.transcript">
                  <p>
                    <span v-if="segment.end - segment.start < 30000" class="tag is-success">{{ segment.lang }}</span>
                    <span v-if="segment.end - segment.start > 30000" class="tag is-danger">{{ segment.lang }}</span>
                    <span class="tag is-info ml-1">{{ formatMilliSeconds(segment.end - segment.start) }}</span>
                    <span class="ml-1" @click.prevent="playAudio(article.audioUrl, segment.start, segment.end)">{{ segment.transcript }}</span>
                  </p>
              </div>
            </div>
          </div>

          <footer>
            <div class="tags level-right">
              <span class="tag is-info">{{ article.programName }}</span>
              <span class="tag is-info"><time :datetime="article.publishDateTime">{{ formatDate(article.publishDateTime) }}</time></span>
              <span class="tag is-info"><time :datetime="formatSeconds(article.duration)">{{ formatSeconds(article.duration) }}</time></span>
              <span class="tag is-info">Varianter: {{ article.variants }}</span>
            </div>
          </footer>
        </article>
      </section>
    </div>
  </div>
</template>

<style scoped>
.hero.is-primary {
  color: #111 !important;
  /* background-color: #31a1bd; */
  background-color: #fff !important;
}

.hero.is-primary .title, .hero.is-primary .subtitle {
  color: #111;
}
</style>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Swedish } from 'flatpickr/dist/l10n/sv.js'
import axios from 'axios'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

export default {
  name: 'HomeView',
  data () {
    return {
      articlesLoading: true,
      articles: [],
      audioPlaying: false,
      audioInstance: new Audio(),
      segmentEnd: 0,
      date: `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day}`,
      dateConfig: {
        locale: Swedish,
        dateFormat: 'Y-m-d',
        minDate: '2022-12-18',
        maxDate: 'today',
        onChange: this.onChangeDate
      },
      textFormat: 'flowing'
    }
  },
  async created () {
    await this.fetchArticles()
    const me = this
    me.audioInstance.addEventListener('timeupdate', function () {
      if (me.segmentEnd && me.audioInstance.currentTime >= me.segmentEnd) {
        me.audioInstance.pause()
        me.audioPlaying = false
      }
      // console.log(me.audioInstance.currentTime)
    }, false)
  },
  methods: {
    fetchArticles: async function () {
      this.articlesLoading = true
      try {
        const year = this.$route.params.year
        const month = this.$route.params.month
        const day = this.$route.params.day
        const response = await axios.get(`https://api.textradio.se/v1/articles/${year}/${month}/${day}`)
        this.articles = response.data.sort((a, b) => { return dayjs(a.publishDateTime).isBefore(dayjs(b.publishDateTime)) ? 1 : -1 })
      } catch (err) {
        console.error(err)
      }
      this.articlesLoading = false
    },
    formatDate: function (dateString) {
      return 'kl. ' + dayjs(dateString).format('HH:mm')
    },
    formatSeconds: function (seconds) {
      return seconds < 60 ? dayjs.duration(seconds * 1000).format('ss[s]') : dayjs.duration(seconds * 1000).format('mm[m] ss[s]')
    },
    formatMilliSeconds: function (ms) {
      return ms < 60000 ? dayjs.duration(ms).format('ss[s]') : dayjs.duration(ms).format('mm[m] ss[s]')
    },
    playAudio: function (url, start, end) {
      const me = this
      if (me.audioPlaying) {
        me.audioInstance.pause()
        me.audioPlaying = false
        return
      }
      if (me.audioInstance.src !== url) {
        me.audioInstance.src = url
      }
      me.audioInstance.currentTime = Math.floor(start / 1000)
      this.segmentEnd = Math.floor(end / 1000)
      me.audioInstance.play()
      me.audioPlaying = true
    },
    onChangeDate: async function (selectedDates, dateStr, instance) {
      console.log(dateStr)
      const splitDate = dateStr.split('-')
      this.$router.push({ path: `/articles/${splitDate[0]}/${splitDate[1]}/${splitDate[2]}` })
      await this.fetchArticles()
    }
  },
  components: {
    flatPickr
  }
}
</script>
