<template>
  <div class="episode content container box">
    <h2>{{ episode.programName }}</h2>
    <h3>{{ episode.episodeTitle }}</h3>

    <h4>Beskrivning</h4>
    <p>{{ episode.episodeDescription }}</p>

    <section>
      <!-- <div class="columns">
        <div class="column is-1 has-background-success" v-for="segment in episode.musicSegments" :key="segment.start">
          {{ segment.label }}
        </div>
      </div> -->
      <!-- <StackedBar :data="barData" /> -->
      <h4>Segmentering</h4>
      <div class="radioOne">
        <input type="radio" id="segOnMusic" value="segOnMusic" v-model="segmentationType" @change="onChange($event)">
        <label for="segOnMusic">Musik</label>
      </div>
      <div class="radioTwo">
        <input type="radio" id="segOnVadMusic" value="segOnVadMusic" v-model="segmentationType" @change="onChange($event)">
        <label for="segOnVadMusic">VAD och Musik</label>
      </div>
      <div class="radioThree" v-if="episode.speakers">
        <input type="radio" id="segOnSpeakers" value="segOnSpeakers" v-model="segmentationType" @change="onChange($event)">
        <label for="segOnSpeakers">Talare</label>
      </div>

      <h4>Transkript</h4>
      <Timeline
        :timeline-items="timelineItems"
        :message-when-no-items="noItemsMessage"
        :uniqueTimeline="true"
        :clickHandler="clickHandler"/>

      <!-- <div class="transcript block">
        <div class="segment block" v-for="segment in episode.segments" :key="segment.transcript">
            <p v-if="segment.lang != 'nocaptions'">
              <span v-if="segment.end - segment.start < 30000" class="tag is-success">{{ segment.lang }}</span>
              <span v-if="segment.end - segment.start > 30000" class="tag is-danger">{{ segment.lang }}</span>
              <span class="tag is-info ml-1">{{ formatMilliSeconds(segment.end - segment.start) }}</span>
              <span class="ml-1">{{ segment.transcript }}</span>
            </p>
        </div>
      </div> -->
    </section>
  </div>
</template>

<style scoped>
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

<script>
// import episodeFile from '../assets/programId-1637-episodeId-2096147-audioId-8652706.json'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import Timeline from '../components/Timeline'
// import StackedBar from '../components/StackedBar'
dayjs.extend(duration)

export default {
  name: 'EpisodeView',
  components: {
    Timeline
  },
  data () {
    return {
      audioInstance: new Audio(),
      segmentEnd: 0,
      episode: {},
      // barData: episode.musicSegments.map(s => {
      //   return {
      //     title: s.label === 'music' ? 'Musik' : 'Tal',
      //     value: s.end - s.start,
      //     color: s.label === 'music' ? '#007bff' : '#20c997'
      //   }
      // }),
      noItemsMessage: 'Inget transkript hittades',
      timelineItems: [],
      segmentationType: 'segOnMusic'
    }
  },
  async created () {
    const response = await fetch(`/episodes/programId-${this.$route.params.programId}-episodeId-${this.$route.params.episodeId}-audioId-${this.$route.params.audioId}.json`)
    this.episode = await response.json()
    this.timelineItems = this.segmentMusic()
    const me = this
    me.audioInstance.addEventListener('timeupdate', function () {
      if (me.segmentEnd && me.audioInstance.currentTime >= me.segmentEnd) {
        me.audioInstance.pause()
        me.audioPlaying = false
      }
      // console.log(me.audioInstance.currentTime)
    }, false)
  },
  methods: {
    formatSeconds: function (seconds) {
      if (seconds < 60) {
        return dayjs.duration(seconds * 1000).format('s[s]')
      } else if (seconds < 3600) {
        return dayjs.duration(seconds * 1000).format('m[m] s[s]')
      } else {
        return dayjs.duration(seconds * 1000).format('H[t] m[m] s[s]')
      }
    },
    formatMilliSeconds: function (ms) {
      return ms < 60000 ? dayjs.duration(ms).format('ss[s]') : dayjs.duration(ms).format('mm[m] ss[s]')
    },
    clickHandler: function (event) {
      console.log(event)
      const me = this
      if (!me.audioInstance.paused) {
        me.audioInstance.pause()
        return
      }
      if (me.audioInstance.src !== me.episodeaudioUrl) {
        me.audioInstance.src = me.episode.audioUrl
      }
      me.audioInstance.currentTime = Math.floor(event.start / 1000)
      this.segmentEnd = Math.floor(event.end / 1000)
      me.audioInstance.play()
    },
    onChange: function (event) {
      if (this.segmentationType === 'segOnVadMusic') {
        this.timelineItems = this.segmentVadMusic()
      }
      if (this.segmentationType === 'segOnMusic') {
        this.timelineItems = this.segmentMusic()
      }
      if (this.segmentationType === 'segOnSpeakers') {
        this.timelineItems = this.segmentSpeakers()
      }
    },
    getEpisodeSegments: function () {
      return this.episode.segments
        .filter(s => s.lang !== 'nocaptions')
        .filter(s => s.transcript !== '((NO-SYSTEMS)))')
        .filter(s => s.transcript !== '(. . . tyst under denna inspelning. . . )')
        .filter(s => s.transcript !== 'Svensktextning.nu')
        .filter(s => s.transcript !== 'Svensk textning.nu')
        .filter(s => s.transcript !== 'Svensk texting.nu')
        .filter(s => s.transcript !== 'Undertexter från Amara.org-gemenskapen')
        .filter(s => s.transcript !== 'www.btistudios.com')
        .filter(s => s.transcript !== 'Textning Stina Hedin www.btistudios.com')
        .filter(s => s.transcript !== 'WW.SWEDS217')
        .filter(s => s.transcript !== 'WS Berggren')
        .filter(s => s.transcript !== 'Svansktextning. Stina Hedin www.btistudios.com')
        .filter(s => s.transcript !== 'Svenskextning.nu')
        .filter(s => s.transcript !== 'Svenskeexting.nu')
        .filter(s => s.transcript !== 'Svenskexting.nu')
        .filter(s => s.end / 1000 - s.start / 1000 > 1)
    },
    segmentVadMusic: function () {
      return this.getEpisodeSegments()
        .concat(
          this.episode.musicSegments
            .filter(s => s.label === 'music')
            .filter(s => s.end - s.start > 1)
            .map(ms => {
              return {
                start: ms.start * 1000,
                end: ms.end * 1000,
                label: ms.label
              }
            })
        )
        .sort((a, b) => {
          if (a.start < b.start) {
            return -1
          }
          if (a.start > b.start) {
            return 1
          }
          return 0
        })
        .map(segment => {
          const speakers = this.episode.speakers ? this.episode.speakers.filter(s => s.start * 1000 >= segment.start && s.end * 1000 <= segment.end) : []
          const speakerLabel = speakers.length > 0 ? ` - Talare ${speakers.map(s => s.speaker).join(', ')}` : ''
          return {
            from: new Date(),
            heading: `${this.formatSeconds(segment.start / 1000)} => ${this.formatSeconds(segment.end / 1000)}`,
            title: segment.label === 'music' ? 'Musik' : `Tal - ${segment.lang}${speakerLabel}`,
            description: segment.transcript,
            start: segment.start,
            end: segment.end
          }
        })
    },
    segmentSpeakers: function () {
      return this.episode.speakers
        .filter(s => s.end - s.start > 1)
        .map(ss => {
          return {
            from: new Date(),
            heading: `${this.formatSeconds(ss.start)} => ${this.formatSeconds(ss.end)}`,
            title: `Talare ${ss.speaker}`,
            description: this.getEpisodeSegments()
              .filter(s => s.start / 1000 >= ss.start && s.end / 1000 <= ss.end)
              .map(s => s.transcript).join('<br><br>'),
            start: ss.start * 1000,
            end: ss.end * 1000
          }
        })
    },
    segmentMusic: function () {
      return this.episode.musicSegments
        .filter(s => s.end - s.start > 1)
        .map(ms => {
          return {
            start: ms.start * 1000,
            end: ms.end * 1000,
            label: ms.label
          }
        })
        .map(ms => {
          return {
            from: new Date(),
            heading: `${this.formatSeconds(ms.start / 1000)} => ${this.formatSeconds(ms.end / 1000)}`,
            title: ms.label === 'music' ? 'Musik' : 'Tal',
            description: this.getEpisodeSegments()
              .filter(s => s.start >= ms.start && s.end <= ms.end)
              .map(s => `${s.transcript}`)
              .join(' '),
            start: ms.start,
            end: ms.end
          }
        })
    }
  }
}
</script>
